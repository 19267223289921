import { LinearProgress } from '@material-ui/core';
import { greenDarkPh, redDarkPh, yellowDarkPh } from 'controlTower/chartUtils/colors';
import React from 'react';
import { useQuery } from 'react-query';
import BottomNavSales from 'sales/BottomNavSales';
import { StringParam, useQueryParam } from 'use-query-params';
import { useDate, useUser } from 'utils/customHooks';
import fetch from 'utils/fetch';
import { format0, isVal } from 'utils/utils';

import TopNav from './TopNav';

const details = {
  daily: {
    salesValue: (data) => {
      let value = data.ptmAverage ? (data.salesValue || 0) / data.ptmAverage : 0;
      let textContent = `${Math.round(data.salesValue || 0).toLocaleString('en-US')}/${Math.round(
        data.ptmAverage
      ).toLocaleString('en-US')} (${
        data.ptmAverage ? Math.round(((data.salesValue || 0) * 100) / data.ptmAverage) : 100
      }%)`;
      let color = value > 0.8 ? greenDarkPh : value > 0.7 ? yellowDarkPh : redDarkPh;
      if ((data.salesValue !== 0 && !data.salesValue) || !data.ptmAverage) {
        color = 'grey';
        value = -1;
      }
      let salesFldVal = {
        value: value,
        textContent: textContent,
        color: color,
      };
      return salesFldVal;
    },
    salesRep: (data) => {
      let value = data.reportedCount || 0;
      let textContent = data.reportedCount ? 'P' : 'A';
      let color = value >= 1 ? greenDarkPh : redDarkPh;
      if (data?.reportedCount !== 0 && !data.reportedCount) {
        color = 'grey';
        value = -1;
      }
      let salesFldVal = {
        value: value,
        textContent: textContent,
        color: color,
      };
      return salesFldVal;
    },
    callCompliance: (data) => {
      let value = data.dashboard.total.value
        ? (data.dashboard.visits.value + data.dashboard.passedBy.value) / data.dashboard.total.value
        : 0;
      let textContent = `${(
        data.dashboard.visits.value + data.dashboard.passedBy.value
      ).toLocaleString('en-US')} /
      ${data.dashboard.total.value.toLocaleString('en-US')} (${Math.round(
        ((data.dashboard.visits.value + data.dashboard.passedBy.value) * 100) /
          data.dashboard.total.value
      )}%)`;
      let color = value > 0.8 ? greenDarkPh : value > 0.7 ? yellowDarkPh : redDarkPh;
      if (!data.dashboard.total.value) {
        color = 'grey';
        value = -1;
      }
      let salesFldVal = {
        value: value,
        textContent: textContent,
        color: color,
      };
      return salesFldVal;
    },
    callProductivity: (data) => {
      let value = data.dashboard.total.value
        ? (data.productive || 0) / data.dashboard.total.value
        : 0;
      let textContent = `${(data.productive || 0).toLocaleString('en-US')}/ ${(
        data.dashboard.total.value || 0
      ).toLocaleString('en-US')} (${Math.round(
        ((data.productive || 0) * 100) / data.dashboard.total.value
      )}%)`;
      let color = value > 0.8 ? greenDarkPh : value > 0.7 ? yellowDarkPh : redDarkPh;
      if ((data.productive !== 0 && !data.productive) || !data.dashboard.total.value) {
        color = 'grey';
        value = -1;
      }
      let salesFldVal = {
        value: value,
        textContent: textContent,
        color: color,
      };
      return salesFldVal;
    },
    activeStores: (data) => {
      let value = data.beatRetailersCount
          ? data.activeBeatRetailersCount / data.beatRetailersCount
          : 0,
        textContent = `${data.activeBeatRetailersCount.toLocaleString(
          'en-US'
        )}/${data.beatRetailersCount.toLocaleString('en-US')} (${Math.round(
          (data.activeBeatRetailersCount * 100) / data.beatRetailersCount
        )}%)`;
      let color = value > 0.9 ? greenDarkPh : value > 0.8 ? yellowDarkPh : redDarkPh;
      if (
        (data.activeBeatRetailersCount !== 0 && !data.activeBeatRetailersCount) ||
        !data.beatRetailersCount
      ) {
        color = 'grey';
        value = -1;
      }
      let salesFldVal = {
        value: value,
        textContent: textContent,
        color: color,
      };
      return salesFldVal;
    },
    dgp: (data) => {
      let value = data.dgpAchieved / data.dgpTarget,
        textContent = data?.dgpTarget
          ? `${data.dgpAchieved}/${data.dgpTarget} (${format0(
              (data?.dgpAchieved * 100) / data?.dgpTarget
            )}%)`
          : `${data.dgpAchieved}/0 (0%)`,
        extraTextContent = data?.prevVisitDgpTarget
          ? `${data?.prevVisitDgpAchieved} / ${data.prevVisitDgpTarget} (${format0(
              (data?.prevVisitDgpAchieved * 100) / data?.prevVisitDgpTarget
            )}%)`
          : `${data.prevVisitDgpAchieved} / 0 (0%)`,
        extraTextContentHeading = 'Previous Routes DGP';
      let color = value >= 0.9 ? greenDarkPh : redDarkPh;
      if ((data.dgpAchieved !== 0 && !data.dgpAchieved) || !data.dgpTarget) {
        color = 'grey';
        value = -1;
      }
      let salesFldVal = {
        value: value,
        textContent: textContent,
        color: color,
        extraTextContent: extraTextContent,
        extraTextContentHeading: extraTextContentHeading,
      };
      return salesFldVal;
    },
    igp: (data) => {
      let value = data.igpAchieved / data.igpTarget,
        textContent = data?.dgpTarget
          ? `${data.igpAchieved}/${data.igpTarget} (${format0(
              (data?.igpAchieved * 100) / data?.igpTarget
            )}%)`
          : `${data.igpAchieved}/0 (0%)`;
      let color = value >= 0.9 ? greenDarkPh : redDarkPh;
      if ((data.igpAchieved !== 0 && !data.igpAchieved) || !data.igpTarget) {
        color = 'grey';
        value = -1;
      }
      let salesFldVal = {
        value: value,
        textContent: textContent,
        color: color,
      };
      return salesFldVal;
    },
    edgeScore: (data) => {
      let value = data.linesCut / data.linesTarget;
      let textContent =
        data?.linesCut >= 0 && data?.linesTarget
          ? `${data.linesCut}/${data.linesTarget} (${format0(
              (data.linesCut * 100) / data.linesTarget
            )}%)`
          : `--/-- (0%)`;
      let color = value >= 0.9 ? greenDarkPh : redDarkPh;
      if (!isVal(data.linesCut) || !isVal(data.linesTarget)) {
        color = 'grey';
        value = -1;
      }
      return {
        value: value,
        textContent: textContent,
        color: color,
      };
    },
    mtdActiveStores: () => {
      let value = null;
      let textContent = null;
      let salesFldVal = {
        value: value,
        textContent: textContent,
      };
      return salesFldVal;
    },
  },
  MTD: {
    salesValue: (data) => {
      let value = data.monthlySalesTarget ? (data.sales || 0) / data.monthlySalesTarget : 0;
      let textContent = `${Math.round(data.sales || 0).toLocaleString('en-US')}/${Math.round(
        data.monthlySalesTarget || 0
      ).toLocaleString('en-US')} (${
        data.monthlySalesTarget
          ? Math.round(((data.sales || 0) * 100) / data.monthlySalesTarget)
          : 100
      }%)`;
      let color = value > 0.8 ? greenDarkPh : value > 0.7 ? yellowDarkPh : redDarkPh;
      if ((data.sales !== 0 && !data.sales) || !data.monthlySalesTarget) {
        color = 'grey';
        value = -1;
      }
      let salesFldVal = {
        value: value,
        textContent: textContent,
        color: color,
      };
      return salesFldVal;
    },
    salesRep: (data) => {
      let value = data.beatDaysCount ? (data.reportedCount || 0) / data.beatDaysCount : 0;
      let textContent = `${data.reportedCount.toLocaleString(
        'en-US'
      )}/${data.beatDaysCount.toLocaleString('en-US')} (${Math.round(
        (data.reportedCount * 100) / data.beatDaysCount
      )}%)`;
      let color = value >= 1 ? greenDarkPh : redDarkPh;
      if (data.reportedCount !== 0 && !data.beatDaysCount) {
        color = 'grey';
        value = -1;
      }
      let salesFldVal = {
        value: value,
        textContent: textContent,
        color: color,
      };
      return salesFldVal;
    },
    callCompliance: (data) => {
      let value = data.total ? (data.visited + data.passedBy) / data.total : 0,
        textContent = `${
          data.total
            ? `${(data.visited + data.passedBy).toLocaleString(
                'en-US'
              )}/${data.total.toLocaleString('en-US')} (${Math.round(
                ((data.visited + data.passedBy) * 100) / data.total
              )}%)`
            : `${(data.visited + data.passedBy).toLocaleString('en-US')}/0 (0%)`
        }`;
      let color = value > 0.8 ? greenDarkPh : value > 0.7 ? yellowDarkPh : redDarkPh;
      if (!data.total) {
        color = 'grey';
        value = -1;
      }
      let salesFldVal = {
        value: value,
        textContent: textContent,
        color: color,
      };
      return salesFldVal;
    },
    callProductivity: (data) => {
      let value = data.total ? (data.productiveCount || 0) / data.total : 0;
      let textContent = `${
        data.total
          ? `${data.productiveCount.toLocaleString('en-US')}/${data.total.toLocaleString(
              'en-US'
            )} (${Math.round((data.productiveCount * 100) / data.total)}%)`
          : `${(data.productiveCount || 0).toLocaleString('en-US')}/0 (0%)`
      }`;
      let color = value > 0.8 ? greenDarkPh : value > 0.7 ? yellowDarkPh : redDarkPh;
      if ((data.productiveCount !== 0 && !data.productiveCount) || !data.total) {
        color = 'grey';
        value = -1;
      }
      let salesFldVal = {
        value: value,
        textContent: textContent,
        color: color,
      };
      return salesFldVal;
    },
    mtdActiveStores: (data) => {
      let value = data.allBeatRetailersCount
        ? (data.mtdActiveRetailersCount || 0) / data.allBeatRetailersCount
        : 0;
      let textContent = `${(data.mtdActiveRetailersCount || 0).toLocaleString('en-US')}/${(
        data.allBeatRetailersCount || 0
      ).toLocaleString('en-US')} (${Math.round(
        (data.mtdActiveRetailersCount * 100) / data.allBeatRetailersCount
      )}%)`;
      let color = value > 0.8 ? greenDarkPh : value > 0.7 ? yellowDarkPh : redDarkPh;
      if (
        (data.mtdActiveRetailersCount !== 0 && !data.mtdActiveRetailersCount) ||
        !data.allBeatRetailersCount
      ) {
        color = 'grey';
        value = -1;
      }
      let salesFldVal = {
        value: value,
        textContent: textContent,
        color: color,
      };
      return salesFldVal;
    },
    activeStores: (data) => {
      let value = data.allBeatRetailersCount
        ? (data.activeRetailersCount || 0) / data.allBeatRetailersCount
        : 0;
      let textContent = `${(data.activeRetailersCount || 0).toLocaleString('en-US')}/${(
        data.allBeatRetailersCount || 0
      ).toLocaleString('en-US')} (${Math.round(
        (data.activeRetailersCount * 100) / data.allBeatRetailersCount
      )}%)`;
      let color = value > 0.9 ? greenDarkPh : value > 0.8 ? yellowDarkPh : redDarkPh;
      if (
        (data.activeRetailersCount !== 0 && !data.activeRetailersCount) ||
        !data.allBeatRetailersCount
      ) {
        color = 'grey';
        value = -1;
      }
      let salesFldVal = {
        value: value,
        textContent: textContent,
        color: color,
      };
      return salesFldVal;
    },
    dgp: (data) => {
      let value =
          data.dgpAchieved / data.dgpTarget -
          (data.prevMonthDgpAchieved / data.prevMonthDgpTarget) *
            (data?.beatDaysCount / data?.totalWorkingDaysCount),
        textContent = data?.dgpTarget
          ? `${data.dgpAchieved}/${data.dgpTarget} (${format0(
              (data?.dgpAchieved * 100) / data?.dgpTarget
            )}%)`
          : `${data.dgpAchieved}/0 (0%)`,
        extraTextContent = data?.prevDateDgpTarget
          ? `${data?.prevDateDgpAchieved} / ${data.prevDateDgpTarget} (${format0(
              (data?.prevDateDgpAchieved * 100) / data?.prevDateDgpTarget
            )}%)`
          : `${data?.prevDateDgpAchieved} / 0 (0%)`,
        extraTextContentHeading = "Yesterday's DGP";
      let color = value >= 0.9 ? greenDarkPh : redDarkPh;
      if ((data.dgpAchieved !== 0 && !data.dgpAchieved) || !data.dgpTarget) {
        color = 'grey';
        value = -1;
      }
      let salesFldVal = {
        value: value,
        textContent: textContent,
        color: color,
        extraTextContent: extraTextContent,
        extraTextContentHeading: extraTextContentHeading,
      };
      return salesFldVal;
    },
    igp: (data) => {
      let value = data.igpAchieved / data.igpTarget,
        textContent = data?.igpTarget
          ? `${data.igpAchieved}/${data.igpTarget} (${format0(
              (data?.igpAchieved * 100) / data?.igpTarget
            )}%)`
          : `${data.igpAchieved}/0 (0%)`;
      let color = value >= 0.9 ? greenDarkPh : redDarkPh;
      if ((data.igpAchieved !== 0 && !data.igpAchieved) || !data.igpTarget) {
        color = 'grey';
        value = -1;
      }
      let salesFldVal = {
        value: value,
        textContent: textContent,
        color: color,
      };
      return salesFldVal;
    },
    edgeScore: (data) => {
      let value = data.linesCut / data.linesTarget;
      let textContent =
        data?.linesCut >= 0 && data?.linesTarget
          ? `${data.linesCut}/${data.linesTarget} (${format0(
              (data.linesCut * 100) / data.linesTarget
            )}%)`
          : `--/-- (0%)`;
      let color = value >= 0.9 ? greenDarkPh : redDarkPh;
      if (!isVal(data.linesCut) || !isVal(data.linesTarget)) {
        color = 'grey';
        value = -1;
      }
      return {
        value: value,
        textContent: textContent,
        color: color,
      };
    },
    mtdGreenOutlets: (data) => {
      let value = data.achievedOutlets / data.totalOutlets;
      let textContent =
        data?.achievedOutlets >= 0 && data?.totalOutlets
          ? `${data.achievedOutlets}/${data.totalOutlets} (${format0(
              (data.achievedOutlets * 100) / data.totalOutlets
            )}%)`
          : `--/-- (0%)`;
      let color = value >= 0.8 ? greenDarkPh : value >= 0.6 ? yellowDarkPh : redDarkPh;
      if (!isVal(data.achievedOutlets) || !isVal(data.totalOutlets)) {
        color = 'grey';
        value = -1;
      }
      return {
        value: value,
        textContent: textContent,
        color: color,
      };
    },
    mtdMSL: (data) => {
      let value = data.achievedBrands / data.totalBrands;
      let textContent =
        data?.achievedBrands >= 0 && data?.totalBrands
          ? `${data.achievedBrands}/${data.totalBrands} (${format0(
              (data.achievedBrands * 100) / data.totalBrands
            )}%)`
          : `--/-- (0%)`;
      let color = value >= 0.8 ? greenDarkPh : value >= 0.6 ? yellowDarkPh : redDarkPh;
      if (!isVal(data.achievedBrands) || !isVal(data.totalBrands)) {
        color = 'grey';
        value = -1;
      }
      return {
        value: value,
        textContent: textContent,
        color: color,
      };
    },
  },
};

const Ranking = ({ salesFld }) => {
  const date = useDate();
  const [tab = 'daily', setTab] = useQueryParam('tab', StringParam);

  let user = useUser();
  const stlId = user.id;

  const { data: salesmenInfo, status } = useQuery(
    date && stlId && ['salesRep_salesFld', { date, stlId, tab }],
    () =>
      fetch(
        `/salesman/activities/${
          tab === 'daily' ? 'dashboard' : 'mtdDashboard'
        }?date=${date}&stlId=${stlId}`
      )
  );

  const titles = {
    salesValue: 'Sales Value',
    salesRep: 'Sales Rep Present',
    callCompliance: 'Call Compliance',
    callProductivity: 'Call Productivity',
    activeStores: 'P1M Active Stores',
    dgp: tab === 'daily' ? `Today's QDGP` : `MTD QDGP`,
    igp: 'IGP',
    mtdActiveStores: tab === 'daily' ? 'Daily Coverage' : 'Coverage',
    mtdMSL: tab === 'MTD' ? 'Achieved MSL' : '',
    mtdGreenOutlets: tab === 'MTD' ? 'Green Outlets' : '',
  };

  if (status === 'loading' || !salesmenInfo) return <LinearProgress color="secondary" />;

  let filteredData = salesmenInfo
    ?.map((salesman) => {
      salesman.salesFld = details[tab][salesFld](salesman);
      return salesman;
    })
    .sort((salesmanA, salesmanB) => salesmanB.salesFld.value - salesmanA.salesFld.value);
  let current_rank = 0,
    global_rank = 0,
    current_mark = 0;
  for (let i = 0; i < filteredData.length; i++) {
    global_rank += 1;
    if (filteredData[i].salesFld.value != current_mark) {
      current_mark = filteredData[i].salesFld.value;
      current_rank = global_rank;
    }
    filteredData[i].rank = current_rank;
  }

  return (
    <>
      <TopNav onChange={setTab} value={tab} />
      <p className="text-center pb-1 font-semibold text-lg">{titles[salesFld]}</p>
      {filteredData ? (
        tab === 'daily' && titles[salesFld] === 'Daily Coverage' ? null : (
          <>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-3 p-5 pt-0 pb-20">
              {filteredData.map((salesman) => (
                <SalesmanCard
                  salesman={salesman}
                  key={salesman.id}
                  tab={tab}
                  salesFld={salesFld}
                ></SalesmanCard>
              ))}
            </div>
          </>
        )
      ) : null}
      <BottomNavSales index={'none'} />
    </>
  );
};

function SalesmanCard({ salesman, tab, salesFld }) {
  return (
    <div
      className={`bg-white rounded-lg shadow h-full divide-y p-1 px-3 border-2 flex justify-between items-center`}
      style={{ borderColor: salesman.salesFld.color }}
    >
      <div>
        <p className="font-semibold">{`${salesman?.code} ${salesman?.name}`}</p>
        <p className="text-xs text-grey_tertiary">{salesman.salesFld.textContent}</p>
        {salesman.salesFld.extraTextContent ? (
          <p className="text-xs">
            {salesman.salesFld.extraTextContentHeading} -{' '}
            <span className="text-grey_tertiary">{salesman.salesFld.extraTextContent}</span>
          </p>
        ) : null}
      </div>
      {salesFld === 'salesRep' && tab === 'daily' ? null : (
        <div
          className={`rounded-full h-10 w-10 flex items-center justify-center text-white font-semibold text-lg`}
          style={{ backgroundColor: salesman.salesFld.color }}
        >
          {salesman?.rank}
        </div>
      )}
    </div>
  );
}

export default Ranking;
